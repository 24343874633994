import React, { useEffect } from "react";
import BreadCrumb from "Common/BreadCrumb";
import Widgets from "./Widgets";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useRef } from "react";

const Analytics = () => {
  const [show, setShow] = React.useState(false);
  const [selection, setSelection] = React.useState({
    startDate: new Date(2000, 0, 1),
    endDate: new Date(),
    key: "selection",
  });

  const ref = useRef(null);
  function handleSelect(ranges: any) {
    setSelection(ranges.selection);
  }

  useEffect(() => {
    const handleClick = (e: any) => {
      //@ts-ignore
      if (ref.current && !ref.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="relative">
        <BreadCrumb title="Analytics" pageTitle="Dashboards" />
        <div className="flex justify-end mb-6">
          <button
            onClick={() => setShow(!show)}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
          >
            {selection.startDate.toDateString()} -{" "}
            {selection.endDate.toDateString()}
          </button>
          {show && (
            <div
              ref={ref}
              className="absolute z-10 top-0 right-10 mt-12 bg-white rounded-lg shadow-lg dark:bg-zink-900 dark:border dark:border-zink-600 dark:shadow-2xl dark:text-white"
            >
              <DateRangePicker ranges={[selection]} onChange={handleSelect} />
            </div>
          )}
        </div>

        <div className="grid grid-cols-12 gap-x-5">
          <Widgets
            startDate={selection.startDate}
            endDate={selection.endDate}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Analytics;
