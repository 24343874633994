import './assets/scss/themes.scss';
import RouteIndex from 'Routes/Index';
 
function App() {
  return (
    <RouteIndex />
  );
}

export default App;
