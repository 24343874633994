import React, { useEffect, useMemo, useState, useCallback } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import PhotosUploader from "components/Forms/ImageUploader";
import { useAuthStore } from "store/useAuthStore";
import { Loader } from "lucide-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "Common/Components/Modal";

// Icons
import { MoreHorizontal, Search, Trash } from "lucide-react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { Dropdown } from "Common/Components/Dropdown";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [blogList, setblogList] = useState([]);
  const [Blogs, setBlogs] = useState<any>([]);
  const [creating, setCreating] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [show, setShow] = useState(false);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
      slug: "",
      content: "",
      authorName: "",
      authorEmail: "",
      tags: [],
      category: "",
      featuredImage: "",
      seoTitle: "",
      seoDescription: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      slug: Yup.string().required("Slug is required"),
      content: Yup.string().required("Content is required"),
      authorName: Yup.string().required("Author Name is required"),
      authorEmail: Yup.string().required("Author Email is required"),
      tags: Yup.array().required("Tags is required"),
      category: Yup.string().required("Category is required"),
      featuredImage: Yup.string().required("Featured Image is required"),
      seoTitle: Yup.string().required("SEO Title is required"),
      seoDescription: Yup.string().required("SEO Description is required"),
    }),

    onSubmit: async (values) => {
      const newData = {
        ...values,
      };
      setCreating(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URI}/blog`,
          {
            ...newData,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        handleFetchBlogs();
        toast.success("Blog created successfully");
        toggle();
        validation.resetForm();
      } catch (error: any) {
        if (!error.response) {
          return toast.error("Network error. Please try again.");
        }
        if (typeof error.response.data === "string") {
          return toast.error(error.response.data);
        }
      } finally {
        setCreating(false);
      }
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
      validation.resetForm();
    }
  }, [show, validation]);

  useEffect(() => {
    handleFetchBlogs();
  }, []);

  useEffect(() => {
    setBlogs(blogList);
  }, [blogList]);

  // fetch data
  const handleFetchBlogs = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URI}/blog`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setblogList(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteblog = async (id: string) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_BASE_URI}/blog/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.status === 200) {
        toast.success("blog deleted successfully!");
        handleFetchBlogs();
      }
    } catch (error) {
      toast.error("Failed to delete blog!");
      console.log(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "Blog ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="#!"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to="#!"
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            {cell.getValue()}
          </Link>
        ),
      },
      {
        header: "Author Name",
        accessorKey: "authorName",
        enableColumnFilter: false,
      },
      {
        header: "Author Email",
        accessorKey: "authorEmail",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span
            onClick={() => {
              toast.success(cell.getValue(), {
                duration: 10000,
              });
            }}
            className="text-blue-500 cursor-pointer"
          >
            Click to view
          </span>
        ),
      },

      {
        header: "Date and Time",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span className="text-slate-500 dark:text-zink-200">
            {moment(cell.getValue()).format("DD/MM/YYYY hh:mm A")}
          </span>
        ),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger
              id="orderAction1"
              data-bs-toggle="dropdown"
              className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
            >
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content
              placement={cell.row.index ? "top-end" : "right-end"}
              className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600"
              aria-labelledby="orderAction1"
            >
              <li onClick={() => deleteblog(cell.row.original._id)}>
                x
                <p className="block cursor-pointer px-4 py-1.5 text-base transition-all duration-200 ease-linear text-red-500 hover:bg-red-100 hover:text-red-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Trash className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Delete</span>
                </p>
              </li>
              <li onClick={() => navigate(`/Blogs/${cell.row.original._id}`)}>
                <p className="block cursor-pointer px-4 py-1.5 text-base transition-all duration-200 ease-linear text-custom-500 hover:bg-custom-100 hover:text-custom-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  <Trash className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                  <span className="align-middle">Details</span>
                </p>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["_id", "licenseNumber", "createdAt", "blog"];
    const searchResult = blogList.filter((item: any) => {
      return keysToSearch.some((key) => {
        return (
          item[key] &&
          item[key].toString().toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    setblogList(searchResult);
  };

  const [content, setContent] = useState("");
  const [tag, setTag] = useState("");

  useEffect(() => {
    if (images.length > 0) {
      validation.setFieldValue("featuredImage", images[0]);
    } else {
      validation.setFieldValue("featuredImage", "");
    }
  }, [images]);

  return (
    <React.Fragment>
      <BreadCrumb title="Website Blogs" pageTitle="Blogs" />
      <ToastContainer closeButton={false} limit={1} />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="Blogs">
            <div className="card-body">
              <div className="flex gap-2">
                <div className="ml-auto">
                  <button
                    onClick={toggle}
                    type="button"
                    className="bg-white mr-3 text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                  >
                    Add Blog
                  </button>
                </div>
              </div>
              <div className="flex items-center">
                <h6 className="text-15 grow">Blogs List</h6>
              </div>
              <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                <form action="#!">
                  <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                    <div className="relative xl:col-span-2">
                      <input
                        type="text"
                        className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Search for name, email, phone number etc..."
                        autoComplete="off"
                        onChange={(e) => filterSearchData(e)}
                      />
                      <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-body">
              {Blogs && Blogs.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={Blogs || []}
                  customPageSize={10}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100 dark:bg-zink-600 after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    <p className="mb-0 text-slate-500 dark:text-zink-200">
                      We've searched more than 199+ users We did not find any
                      users for you search.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Blog Modal  */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed min-h-screen flex flex-col transition-all duration-300 ease-in-out top-0 left-0 w-full z-drawer "
        dialogClassName="w-screen min-h-screen bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Create a new Blog"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3 max-w-[400px]">
              <p
                className="
              text-xl
              "
              >
                Upload Featured Image
              </p>
              <PhotosUploader
                addedPhotos={images}
                onChange={setImages}
                maxPhotos={1}
              />
              {validation.touched.featuredImage && (
                <p className="text-red-500">
                  {validation.errors.featuredImage}
                </p>
              )}
            </div>
            <div className="grid grid-cols-1  md:grid-cols-2">
              <div className="mb-3">
                <label
                  htmlFor="title"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="form-input max-w-2xl w-full"
                  placeholder="Title"
                  {...validation.getFieldProps("title")}
                />
                {validation.touched.title && validation.errors.title ? (
                  <p className="text-red-500">{validation.errors.title}</p>
                ) : null}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Description
                </label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  className="form-input max-w-2xl"
                  placeholder="Description"
                  {...validation.getFieldProps("description")}
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <p className="text-red-500">
                    {validation.errors.description}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-2">
              <div className="mb-3">
                <label
                  htmlFor="slug"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Slug
                </label>
                <input
                  type="text"
                  id="slug"
                  name="slug"
                  className="form-input max-w-2xl w-full"
                  placeholder="slug"
                  {...validation.getFieldProps("slug")}
                />
                {validation.touched.slug && validation.errors.slug ? (
                  <p className="text-red-500">{validation.errors.slug}</p>
                ) : null}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="category"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Category
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  className="form-input max-w-2xl"
                  placeholder="category"
                  {...validation.getFieldProps("category")}
                />
                {validation.touched.category && validation.errors.category ? (
                  <p className="text-red-500">{validation.errors.category}</p>
                ) : null}
              </div>
            </div>
            <div className="grid grid-cols-1  md:grid-cols-2">
              <div className="mb-3">
                <label
                  htmlFor="seoTitle"
                  className="inline-block mb-2 text-base font-medium"
                >
                  SEO Title
                </label>
                <input
                  type="text"
                  id="seoTitle"
                  name="seoTitle"
                  className="form-input max-w-2xl w-full"
                  placeholder="SEO Title"
                  {...validation.getFieldProps("seoTitle")}
                />
                {validation.touched.seoTitle && validation.errors.seoTitle ? (
                  <p className="text-red-500">{validation.errors.seoTitle}</p>
                ) : null}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="seoDescription"
                  className="inline-block mb-2 text-base font-medium"
                >
                  SEO Description
                </label>
                <input
                  type="text"
                  id="seoDescription"
                  name="seoDescription"
                  className="form-input max-w-2xl"
                  placeholder="seoDescription"
                  {...validation.getFieldProps("seoDescription")}
                />
                {validation.touched.seoDescription &&
                validation.errors.seoDescription ? (
                  <p className="text-red-500">
                    {validation.errors.seoDescription}
                  </p>
                ) : null}
              </div>
            </div>

            <Editor
              apiKey="kkgix7ynm9ljb8dbvuua8tj2q3n18v36em3vrdxcg0aiy4id"
              init={{
                plugins:
                  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate mentions tableofcontents footnotes mergetags autocorrect typography inlinecss markdown",
                toolbar:
                  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
              }}
              value={content}
              onEditorChange={(content) => {
                setContent(content);
              }}
              initialValue={validation.values.content}
            />
            {validation.touched.content && validation.errors.content ? (
              <p className="text-red-500">{validation.errors.content}</p>
            ) : null}

            <div className="grid mt-3 grid-cols-1  md:grid-cols-2">
              <div className="mb-3">
                <label
                  htmlFor="authorName"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Author Name
                </label>
                <input
                  type="text"
                  id="authorName"
                  name="authorName"
                  className="form-input max-w-2xl w-full"
                  placeholder="Author Name"
                  {...validation.getFieldProps("authorName")}
                />
                {validation.touched.authorName &&
                validation.errors.authorName ? (
                  <p className="text-red-500">{validation.errors.authorName}</p>
                ) : null}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="authorEmail"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Author Email
                </label>
                <input
                  type="text"
                  id="authorEmail"
                  name="authorEmail"
                  className="form-input max-w-2xl"
                  placeholder="Author Email"
                  {...validation.getFieldProps("authorEmail")}
                />
                {validation.touched.authorEmail &&
                validation.errors.authorEmail ? (
                  <p className="text-red-500">
                    {validation.errors.authorEmail}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="mb-3">
              <label
                htmlFor="tags"
                className="inline-block mb-2 text-base font-medium"
              >
                Tags
              </label>
              {validation.values.tags.map((tag: any, index: any) => (
                <div key={index} className="flex gap-2">
                  <input
                    type="text"
                    id="tags"
                    name="tags"
                    className="form-input max-w-2xl"
                    placeholder="SEO"
                    value={tag}
                    onChange={(e) => {
                      validation.setFieldValue("tags", [
                        ...validation.values.tags.slice(0, index),
                        e.target.value,
                        ...validation.values.tags.slice(index + 1),
                      ]);
                    }}
                  />
                  <button
                    type="button"
                    className="btn bg-custom-500 text-white"
                    onClick={() => {
                      validation.setFieldValue(
                        "tags",
                        validation.values.tags.filter(
                          (tag: any, i: any) => i !== index
                        )
                      );
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <div>
                <input
                  type="text"
                  id="tags"
                  name="tags"
                  className="form-input max-w-2xl"
                  placeholder="SEO"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                />
                <button
                  type="button"
                  className="btn bg-custom-500 text-white"
                  onClick={() => {
                    validation.setFieldValue(
                      "tags",
                      validation.values.tags.concat(tag)
                    );
                    setTag("");
                  }}
                >
                  Add
                </button>
              </div>

              {validation.touched.tags && validation.errors.tags ? (
                <p className="text-red-500">{validation.errors.tags}</p>
              ) : null}
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                data-modal-close="addDocuments"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  validation.setFieldValue("content", content);
                  validation.handleSubmit();
                }}
                type="button"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {creating ? "Creating..." : "Create"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Blogs;
