import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Loader, Calendar, Facebook, Instagram, Users, Image, Link as LinkIcon, Info, MessageSquare } from "lucide-react";
import toast from "react-hot-toast";
import BreadCrumb from "Common/BreadCrumb";
import ReportGenerator from "components/Report/ReportGenerator";

const PageDetails = () => {
  const { pageId } = useParams();
  const [pageDetails, setPageDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [postCounts, setPostCounts] = useState({ facebook: 0, instagram: 0 });
  const [customNote, setCustomNote] = useState("");

  useEffect(() => {
    fetchPageDetails();
  }, [pageId]);

  const fetchPageDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/smaccount/page/${pageId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setPageDetails(response.data);
    } catch (error) {
      console.log("Error fetching page details:", error);
      toast.error("Failed to fetch page details");
    } finally {
      setLoading(false);
    }
  };

  const fetchPostCounts = async () => {
    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/smaccount/page/${pageId}/post-counts`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            startDate,
            endDate,
          },
        }
      );
      setPostCounts(response.data);
    } catch (error) {
      console.log("Error fetching post counts:", error);
      toast.error("Failed to fetch post counts");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader className="w-12 h-12 text-custom-500 animate-spin" />
      </div>
    );
  }

  if (!pageDetails) {
    return <div className="text-center text-lg text-gray-600">No page details found</div>;
  }

  return (
    <React.Fragment>
      <BreadCrumb title="Page Details" pageTitle="Social Media" />
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <h2 className="text-3xl font-bold text-gray-800 mb-6 flex items-center">
            <Info className="mr-2 text-blue-500" />
            {pageDetails.name}
          </h2>

          {/* Date Range Filter */}
          <div className="mb-8 bg-gray-100 p-4 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-700 mb-4 flex items-center">
              <Calendar className="mr-2 text-blue-500" />
              Date Range Filter and Report Generation
            </h3>
            <div className="flex flex-wrap items-center gap-4 mb-4">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="form-input border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="form-input border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
              <button
                onClick={fetchPostCounts}
                className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out flex items-center"
              >
                <MessageSquare className="mr-2" />
                Fetch Post Counts
              </button>
            </div>
            <div className="flex flex-wrap items-center gap-4">
              <input
                type="text"
                value={customNote}
                onChange={(e) => setCustomNote(e.target.value)}
                placeholder="Add a custom note to the report"
                className="form-input border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 flex-grow"
              />
              <ReportGenerator
                //@ts-ignore
                pageId={pageId}
                startDate={startDate}
                endDate={endDate}
                customNote={customNote}
              />
            </div>
          </div>


          {/* Post Counts Display */}
          {(postCounts.facebook > 0 || postCounts.instagram > 0) && (
            <div className="mb-8 bg-blue-100 p-6 rounded-lg">
              <h3 className="text-xl font-semibold text-blue-800 mb-4">Post Counts</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center">
                  <Facebook className="w-8 h-8 text-blue-600 mr-3" />
                  <span className="text-lg">
                    <strong>Facebook Posts:</strong> {postCounts.facebook}
                  </span>
                </div>
                <div className="flex items-center">
                  <Instagram className="w-8 h-8 text-pink-600 mr-3" />
                  <span className="text-lg">
                    <strong>Instagram Posts:</strong> {postCounts.instagram}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Facebook Details */}
            <div className="bg-blue-50 p-6 rounded-lg">
              <h3 className="text-2xl font-semibold text-blue-800 mb-4 flex items-center">
                <Facebook className="mr-2" />
                Facebook Page Details
              </h3>
              <div className="space-y-3">
                <p className="flex items-center">
                  <Info className="w-5 h-5 mr-2 text-blue-500" />
                  <strong>ID:</strong> {pageDetails.id}
                </p>
                <p className="flex items-center">
                  <Info className="w-5 h-5 mr-2 text-blue-500" />
                  <strong>Category:</strong> {pageDetails.category}
                </p>
                <p className="flex items-center">
                  <Users className="w-5 h-5 mr-2 text-blue-500" />
                  <strong>Fan Count:</strong> {pageDetails.fanCount}
                </p>
                <p className="flex items-center">
                  <LinkIcon className="w-5 h-5 mr-2 text-blue-500" />
                  <strong>Link:</strong>{" "}
                  <a
                    href={pageDetails.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline ml-1"
                  >
                    {pageDetails.link}
                  </a>
                </p>
                <p className="flex items-center">
                  <Info className="w-5 h-5 mr-2 text-blue-500" />
                  <strong>Status:</strong>{" "}
                  <span className={pageDetails.isFacebookActive ? "text-green-600 ml-1" : "text-red-600 ml-1"}>
                    {pageDetails.isFacebookActive ? "Active" : "Inactive"}
                  </span>
                </p>
              </div>
            </div>

            {/* Instagram Details */}
            {pageDetails.instagramAccount && (
              <div className="bg-pink-50 p-6 rounded-lg">
                <h3 className="text-2xl font-semibold text-pink-800 mb-4 flex items-center">
                  <Instagram className="mr-2" />
                  Instagram Account Details
                </h3>
                <div className="space-y-3">
                  <p className="flex items-center">
                    <Info className="w-5 h-5 mr-2 text-pink-500" />
                    <strong>ID:</strong> {pageDetails.instagramAccount.id}
                  </p>
                  <p className="flex items-center">
                    <Info className="w-5 h-5 mr-2 text-pink-500" />
                    <strong>Username:</strong> {pageDetails.instagramAccount.username}
                  </p>
                  <p className="flex items-center">
                    <Users className="w-5 h-5 mr-2 text-pink-500" />
                    <strong>Followers:</strong> {pageDetails.instagramAccount.followersCount}
                  </p>
                  <p className="flex items-center">
                    <Image className="w-5 h-5 mr-2 text-pink-500" />
                    <strong>Media Count:</strong> {pageDetails.instagramAccount.mediaCount}
                  </p>
                  <p className="flex items-center">
                    <Info className="w-5 h-5 mr-2 text-pink-500" />
                    <strong>Status:</strong>{" "}
                    <span className={pageDetails.instagramAccount.isInstagramActive ? "text-green-600 ml-1" : "text-red-600 ml-1"}>
                      {pageDetails.instagramAccount.isInstagramActive ? "Active" : "Inactive"}
                    </span>
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Recent Posts */}
          <div className="mt-8">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
              <MessageSquare className="mr-2 text-blue-500" />
              Recent Posts
            </h3>
            {pageDetails.recentPosts && pageDetails.recentPosts.length > 0 ? (
              <ul className="space-y-4">
                {pageDetails.recentPosts.map((post: any) => (
                  <li key={post.id} className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-gray-800">
                      {post.message
                        ? post.message.substring(0, 100) + "..."
                        : "No message"}
                    </p>
                    <p className="text-sm text-gray-500 mt-2">
                      Posted on: {new Date(post.created_time).toLocaleString()}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">No recent posts found.</p>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageDetails;