import BreadCrumb from "Common/BreadCrumb";
import React from "react";
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import * as XLSX from "xlsx";
import Modal from "Common/Components/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import PhotosUploader from "components/Forms/ImageUploader";
import VideoUploader from "components/Forms/VideoUploader";
import { Loader } from "lucide-react";

// Icons
import { Download, Search } from "lucide-react";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import toast from "react-hot-toast";

const SMPostings = () => {
  const [SMPostingList, setSMPostingList] = useState([]);
  const [creatingSMPosting, setCreatingSMPosting] = useState<boolean>(false);
  const [SMPostings, setSMPostings] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [videos, setVideos] = useState<string[]>([]);
  const [postToFacebook, setPostToFacebook] = useState(true);
  const [postToInstagram, setPostToInstagram] = useState(true);

  const toggle = () => {
    setShow(!show);
  };

  useEffect(() => {
    const mediaURLs = [...images, ...videos];
    validation.setFieldValue("mediaURLs", mediaURLs);

    let mediaType;
    if (videos.length > 0) {
      mediaType = "video";
    } else if (mediaURLs.length > 1) {
      mediaType = "carousel";
    } else {
      mediaType = "image";
    }
    validation.setFieldValue("mediaType", mediaType);
  }, [images, videos]);

  const validation = useFormik({
    initialValues: {
      name: "",
      message: "",
      mediaURLs: [],
      mediaType: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Name is required"),
      message: yup.string().required("Message is required"),
      mediaURLs: yup
        .array()
        .min(1, "At least one media item is required")
        .max(10, "Maximum 10 media items allowed"),
      mediaType: yup.string().required("Media type is required"),
    }),
    onSubmit: async (values) => {
      if (!postToFacebook && !postToInstagram) {
        return toast.error("Please select at least one platform to post to");
      }

      let page = SMPostings.find((item: any) => item.name === values.name);
      let pageId = page?.id;
      let pageAccessToken = page?.access_token;
      setCreatingSMPosting(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_BASE_URI}/smaccount/post`,
          {
            pageId: pageId,
            pageAccessToken: pageAccessToken,
            message: values.message,
            mediaURLs: values.mediaURLs,
            postToFacebook,
            postToInstagram,
            mediaType: values.mediaType,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        toast.success("Posting created successfully!");
        validation.resetForm();
        setImages([]);
        setVideos([]);
      } catch (error: any) {
        if (typeof error.response === "undefined") {
          return toast.error("Network Error");
        }
        if (typeof error.response.data === "string") {
          return toast.error(error.response.data);
        }
        toast.error("Something went wrong, please try again");
      } finally {
        setCreatingSMPosting(false);
        toggle();
        handleFetchSMPostings();
      }
    },
  });

  useEffect(() => {
    handleFetchSMPostings();
  }, []);

  useEffect(() => {
    setSMPostings(SMPostingList);
  }, [SMPostingList]);

  // fetch data
  const handleFetchSMPostings = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/smaccount/list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setSMPostingList(res.data.data);
      if (res.data.data.length > 0) {
        setPostToFacebook(res.data.data[0].isFacebookActive);
        setPostToInstagram(res.data.data[0].isInstagramActive);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch SM Postings");
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        header: (
          <div className="flex items-center h-full">
            <input
              id="CheckboxAll"
              className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
              type="checkbox"
            />
          </div>
        ),
        enableSorting: false,
        id: "checkAll",
        cell: (cell: any) => {
          return (
            <div className="flex items-center h-full">
              <input
                id="Checkbox1"
                className="size-4 bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800 cursor-pointer"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Link
            to={`/sm-postings/${cell.row.original.id}`}
            className="transition-all duration-150 ease-linear text-custom-500 hover:text-custom-600 user-id"
          >
            {" "}
            {cell.getValue()}{" "}
          </Link>
        ),
      },
      {
        header: "Category",
        accessorKey: "category",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span className="text-slate-500 dark:text-zink-200">
            {cell.getValue()}
          </span>
        ),
      },
      {
        header: "Facebook",
        accessorKey: "isFacebookActive",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span
            className={`${cell.getValue() ? "text-green-500" : "text-red-500"}`}
          >
            {cell.getValue() ? "Active" : "Inactive"}
          </span>
        ),
      },
      {
        header: "Instagram",
        accessorKey: "isInstagramActive",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <span
            className={`${cell.getValue() ? "text-green-500" : "text-red-500"}`}
          >
            {cell.getValue() ? "Active" : "Inactive"}
          </span>
        ),
      },
    ],
    []
  );

  const dowloadSMPostingsInExcel = async () => {
    const data = SMPostings.map((item: any) => {
      return {
        "SMPosting ID": item._id,
      };
    });

    const fileName = "SMPostings";
    const exportType = "xls";
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.${exportType}`);
  };

  const filterSearchData = (e: any) => {
    const search = e.target.value;
    const keysToSearch = ["_id", "licenseNumber", "createdAt", "SMPosting"];
    const searchResult = SMPostingList.filter((item: any) => {
      return keysToSearch.some((key) => {
        return (
          item[key] &&
          item[key].toString().toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    setSMPostingList(searchResult);
  };

  return (
    <React.Fragment>
      <BreadCrumb title="SMPostings" pageTitle="All SMPostings" />
      <ToastContainer closeButton={false} limit={1} />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
        <div className="xl:col-span-12">
          <div className="card" id="SMPostings">
            <div className="card-body">
              <div className="flex gap-2">
                <div className="ml-auto">
                  <button
                    className="text-white transition-all mr-2 duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={() => {
                      toggle();
                    }}
                    type="button"
                  >
                    Create SMPosting
                  </button>
                  <button
                    onClick={dowloadSMPostingsInExcel}
                    type="button"
                    className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
                  >
                    <Download className="inline-block size-4" />{" "}
                    <span className="align-middle">Export</span>
                  </button>
                </div>
              </div>
              <div className="flex items-center">
                <h6 className="text-15 grow">SMPostings List</h6>
              </div>
              <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                <form action="#!">
                  <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                    <div className="relative xl:col-span-2">
                      <input
                        type="text"
                        className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Search for name, email, phone number etc..."
                        autoComplete="off"
                        onChange={(e) => filterSearchData(e)}
                      />
                      <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-body">
              {SMPostings && SMPostings.length > 0 ? (
                <TableContainer
                  isPagination={true}
                  columns={columns || []}
                  data={SMPostings || []}
                  customPageSize={10}
                  divclassName="-mx-5 -mb-5 overflow-x-auto"
                  tableclassName="w-full border-separate table-custom border-spacing-y-1 whitespace-nowrap"
                  theadclassName="text-left relative rounded-md bg-slate-100  after:absolute ltr:after:border-l-2 rtl:after:border-r-2 ltr:after:left-0 rtl:after:right-0 after:top-0 after:bottom-0 after:border-transparent [&.active]:after:border-custom-500 [&.active]:bg-slate-100 dark:[&.active]:bg-zink-600"
                  thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold"
                  tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5"
                  PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
                />
              ) : (
                <div className="noresult">
                  <div className="py-6 text-center">
                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    <p className="mb-0 text-slate-500 dark:text-zink-200">
                      We've searched more than 1000 records and found nothing.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* SM Posting Modal  */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md "
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Create SM Posting"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            action="#!"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <div className="mb-3">
              <label
                htmlFor="title"
                className="inline-block mb-2 text-base font-medium"
              >
                Select Account
              </label>
              <select
                id="name"
                className="form-select border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                name="name"
                onChange={(e) => {
                  validation.setFieldValue("name", e.target.value);
                  const selectedPage = SMPostings.find(
                    (item: any) => item.name === e.target.value
                  );
                  setPostToFacebook(selectedPage?.isFacebookActive || false);
                  setPostToInstagram(selectedPage?.isInstagramActive || false);
                }}
                value={validation.values.name || ""}
              >
                <option value="">Select an account</option>
                {SMPostings.map((item: any) => (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
              {validation.touched.name && validation.errors.name ? (
                <p className="text-red-400">{validation.errors.name}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="message"
                className="inline-block mb-2 text-base font-medium"
              >
                Message
              </label>
              <textarea
                id="message"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder=""
                name="message"
                onChange={validation.handleChange}
                value={validation.values.message || ""}
              />
              {validation.touched.message && validation.errors.message ? (
                <p className="text-red-400">{validation.errors.message}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label className="inline-block mb-2 text-base font-medium">
                Post to:
              </label>
              <div>
                <label className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    checked={postToFacebook}
                    onChange={() => setPostToFacebook(!postToFacebook)}
                    disabled={
                      !SMPostings.find(
                        (item: any) => item.name === validation.values.name
                      )?.isFacebookActive
                    }
                    className="mr-2"
                  />
                  Facebook
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={postToInstagram}
                    onChange={() => setPostToInstagram(!postToInstagram)}
                    disabled={
                      !SMPostings.find(
                        (item: any) => item.name === validation.values.name
                      )?.isInstagramActive
                    }
                    className="mr-2"
                  />
                  Instagram
                </label>
              </div>
            </div>
            <div className="mb-3">
              <label className="inline-block mb-2 text-base font-medium">
                Upload Images (Max 10)
              </label>
              <PhotosUploader
                addedPhotos={images}
                onChange={setImages}
                maxPhotos={videos.length === 0 ? 10 : 0}
              />
            </div>
            <div className="mb-3">
              <label className="inline-block mb-2 text-base font-medium">
                Upload Videos (Max 10)
              </label>
              <VideoUploader
                addedVideos={videos}
                onChange={setVideos}
                maxVideos={images.length === 0 ? 1 : 0}
              />
            </div>
            {validation.touched.mediaURLs && validation.errors.mediaURLs ? (
              <p className="text-red-400">{validation.errors.mediaURLs}</p>
            ) : null}
            {validation.touched.mediaType && validation.errors.mediaType ? (
              <p className="text-red-400">{validation.errors.mediaType}</p>
            ) : null}
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="button"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                onClick={() => {
                  toggle();
                  validation.resetForm();
                  setImages([]);
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={creatingSMPosting}
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {creatingSMPosting ? "Creating..." : "Create"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default SMPostings;
