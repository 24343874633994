import React, { useState, useEffect } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import { Loader, FileText, AlertCircle, RefreshCw } from "lucide-react";
import { ReportDocument, generatePDFWithCover } from "./ReportCover";

interface Platform {
  name: string;
  postCount: number;
  link: string;
}

interface ReportData {
  brandLogo: string;
  companyName: string;
  socialMediaPlatforms: Platform[];
}

interface ReportGeneratorProps {
  pageId: string;
  startDate: string;
  endDate: string;
  customNote: string;
}

const ReportGenerator: React.FC<ReportGeneratorProps> = ({
  pageId,
  startDate,
  endDate,
  customNote,
}) => {
  const [reportData, setReportData] = useState<ReportData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [platforms, setPlatforms] = useState<Platform[]>([]);

  const fetchReportData = async () => {
    if (!startDate || !endDate) {
      return setError("Please select start and end date");
    }
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/smaccount/page/${pageId}/report-data`,
        {
          params: { startDate, endDate },
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const data: ReportData = response.data;
      setReportData(data);
      setPlatforms(data.socialMediaPlatforms);
    } catch (err) {
      setError("Failed to fetch report data");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedPlatforms = [...platforms];
    updatedPlatforms[index] = { ...updatedPlatforms[index], [field]: value };
    setPlatforms(updatedPlatforms);
  };

  const updatedReportData = reportData
    ? {
        ...reportData,
        socialMediaPlatforms: platforms,
      }
    : null;

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4 bg-blue-100 rounded-lg">
        <Loader className="w-6 h-6 text-blue-500 animate-spin mr-2" />
        <span className="text-blue-700">Generating report...</span>
      </div>
    );
  }

  if (!reportData) {
    return (
      <div className="space-y-4">
        <button
          onClick={fetchReportData}
          className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center w-full"
        >
          <FileText className="w-5 h-5 mr-2" />
          Generate Report
        </button>
        {error && (
          <div className="flex items-center justify-center p-4 bg-red-100 rounded-lg text-red-700">
            <AlertCircle className="w-5 h-5 mr-2" />
            {error}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="space-y-4">
        {platforms.map((platform, index) => (
          <div key={platform.name} className="flex space-x-4">
            <input
              type="number"
              placeholder="Posts"
              value={platform.postCount}
              onChange={(e) =>
                handleInputChange(index, "postCount", e.target.value)
              }
              className="px-3 py-2 border rounded"
            />
            <input
              type="text"
              placeholder="Link"
              value={platform.link}
              onChange={(e) => handleInputChange(index, "link", e.target.value)}
              className="px-3 py-2 border rounded"
            />
          </div>
        ))}
      </div>
      <PDFDownloadLink
        document={
          <ReportDocument
            data={updatedReportData}
            startDate={startDate}
            endDate={endDate}
            customNote={customNote}
          />
        }
        fileName={`social_media_report_${pageId}_${startDate}_${endDate}.pdf`}
        className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300 flex items-center justify-center w-full mt-4"
      >
        {({ blob, url, loading, error }) => {
          if (loading) {
            return (
              <>
                <Loader className="w-5 h-5 mr-2 animate-spin" />
                Preparing PDF...
              </>
            );
          }
          return (
            <a
              href="#"
              className="flex items-center justify-center w-full"
              onClick={async (e) => {
                e.preventDefault();
                const pdfBlob = await generatePDFWithCover({
                  data: updatedReportData,
                  startDate,
                  endDate,
                  customNote,
                });
                const url = URL.createObjectURL(pdfBlob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `social_media_report_${pageId}_${startDate}_${endDate}.pdf`;
                link.click();
              }}
            >
              <FileText className="w-5 h-5 mr-2" />
              Download PDF Report
            </a>
          );
        }}
      </PDFDownloadLink>
      <button
        onClick={fetchReportData}
        className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center w-full mt-4"
      >
        <RefreshCw className="w-5 h-5 mr-2" />
        Refresh Report
      </button>
    </div>
  );
};

export default ReportGenerator;