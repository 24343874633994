import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { PDFDocument } from 'pdf-lib';
import logo from 'assets/logo.png';

// Register custom fonts
Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf', fontWeight: 400 },
    { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 700 },
  ],
});

interface ContentSection {
  type: 'paragraph' | 'table';
  title: string;
  content: string | Array<{ key: string; value: string }>;
}

interface ProposalData {
  title: string;
  logoUrl: string;
  sections: ContentSection[];
  footerText: string;
}

// Define styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 40,
    fontFamily: 'Roboto',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  logo: {
    width: 130,
    height: 80,
    objectFit: 'contain',
    marginRight: 20,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#CE1615',
    marginBottom: 10,
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
  },
  table: {
    display: 'flex',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#CE1615',
    borderWidth: 1,
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol1: {
    width: '30%',
    borderStyle: 'solid',
    borderColor: '#CE1615',
    borderWidth: 1,
    backgroundColor: '#CE1615',
  },
  tableCol2: {
    width: '70%',
    borderStyle: 'solid',
    borderColor: '#CE1615',
    borderWidth: 1,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  tableCellWhite: {
    margin: 5,
    fontSize: 10,
    color: 'white',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontSize: 10,
  },
  redBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 20,
    backgroundColor: '#CE1615',
  },
  logoContainer: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
});

const ProposalDocument: React.FC<{ data: ProposalData }> = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.logoContainer}>
        <Image style={styles.logo} src={logo} />
      </View>
      <View style={styles.header}>
        <Text style={styles.title}>{data.title}</Text>
      </View>

      {data.sections.map((section, index) => (
        <View key={index}>
          <Text style={styles.sectionTitle}>{section.title}</Text>
          {section.type === 'paragraph' ? (
            <Text style={styles.text}>{section.content as string}</Text>
          ) : (
            <View style={styles.table}>
              {(section.content as Array<{ key: string; value: string }>).map((row, rowIndex) => (
                <View key={rowIndex} style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCellWhite}>{row.key}</Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>{row.value}</Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      ))}

      <Text style={styles.footer}>{data.footerText}</Text>
      <View style={styles.redBar} />
    </Page>
  </Document>
);

const generatePDFWithCoverAndIntro = async (data: ProposalData) => {
  // Generate the main report PDF
  const reportPdf = await pdf(<ProposalDocument data={data} />).toBlob();
  const pdfDoc = await PDFDocument.load(await reportPdf.arrayBuffer());

  // Fetch and load the intro PDF
  const introPdfUrl = `${process.env.PUBLIC_URL}/INTRO.pdf`;
  const introPdfBytes = await fetch(introPdfUrl).then(res => res.arrayBuffer());
  const introPdf = await PDFDocument.load(introPdfBytes);

  // Copy pages from cover and intro PDFs
  const [introPage] = await pdfDoc.copyPages(introPdf, [0]);

  // Insert the cover and intro pages at the beginning
  pdfDoc.insertPage(0, introPage);

  // Save the combined PDF
  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: 'application/pdf' });
};

const ProposalGenerator: React.FC = () => {
  const [proposalData, setProposalData] = React.useState<ProposalData>({
    title: 'Our Proposal',
    logoUrl: '/path/to/logo.png',
    sections: [
      {
        type: 'paragraph',
        title: 'Introduction',
        content: 'Enter your introduction here...'
      }
    ],
    footerText: 'CONFIDENTIAL'
  });

  const handleInputChange = (index: number, field: string, value: string) => {
    setProposalData(prevData => {
      const newSections = [...prevData.sections];
      if (field === 'title') {
        newSections[index].title = value;
      } else if (newSections[index].type === 'paragraph') {
        newSections[index].content = value;
      }
      return { ...prevData, sections: newSections };
    });
  };

  const handleTableChange = (sectionIndex: number, rowIndex: number, field: 'key' | 'value', value: string) => {
    setProposalData(prevData => {
      const newSections = [...prevData.sections];
      if (newSections[sectionIndex].type === 'table') {
        const newContent = [...newSections[sectionIndex].content as Array<{ key: string; value: string }>];
        newContent[rowIndex][field] = value;
        newSections[sectionIndex].content = newContent;
      }
      return { ...prevData, sections: newSections };
    });
  };

  const addTableRow = (sectionIndex: number) => {
    setProposalData(prevData => {
      const newSections = [...prevData.sections];
      if (newSections[sectionIndex].type === 'table') {
        const newContent = [...newSections[sectionIndex].content as Array<{ key: string; value: string }>];
        newContent.push({ key: '', value: '' });
        newSections[sectionIndex].content = newContent;
      }
      return { ...prevData, sections: newSections };
    });
  };

  const addSection = (type: 'paragraph' | 'table') => {
    setProposalData(prevData => {
      const newSections = [...prevData.sections];
      if (type === 'paragraph') {
        newSections.push({
          type: 'paragraph',
          title: 'New Section',
          content: 'Enter your content here...'
        });
      } else {
        newSections.push({
          type: 'table',
          title: 'New Table',
          content: [{ key: 'New Key', value: 'New Value' }]
        });
      }
      return { ...prevData, sections: newSections };
    });
  };

  const removeSection = (index: number) => {
    setProposalData(prevData => {
      const newSections = prevData.sections.filter((_, i) => i !== index);
      return { ...prevData, sections: newSections };
    });
  };

  const downloadPDF = async () => {
    const blob = await generatePDFWithCoverAndIntro(proposalData);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'proposal.pdf';
    a.click();
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Proposal Generator</h1>
      
      <div className="mb-4">
        <button
          onClick={() => addSection('paragraph')}
          className="bg-green-500 text-white px-4 py-2 rounded mr-2"
        >
          Add Paragraph
        </button>
        <button
          onClick={() => addSection('table')}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Table
        </button>
      </div>

      {proposalData.sections.map((section, index) => (
        <div key={index} className="mb-4 p-4 border rounded">
          <div className="flex justify-between items-center mb-2">
            <input
              type="text"
              value={section.title}
              onChange={(e) => handleInputChange(index, 'title', e.target.value)}
              className="w-2/3 p-2 border rounded"
            />
            <button
              onClick={() => removeSection(index)}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Remove
            </button>
          </div>
          
          {section.type === 'paragraph' ? (
            <textarea
              value={section.content as string}
              onChange={(e) => handleInputChange(index, 'content', e.target.value)}
              className="w-full p-2 border rounded"
              rows={4}
            />
          ) : (
            <div>
              {(section.content as Array<{ key: string; value: string }>).map((row, rowIndex) => (
                <div key={rowIndex} className="flex mb-2">
                  <input
                    type="text"
                    value={row.key}
                    onChange={(e) => handleTableChange(index, rowIndex, 'key', e.target.value)}
                    className="w-1/2 p-2 border rounded mr-2"
                    placeholder="Key"
                  />
                  <input
                    type="text"
                    value={row.value}
                    onChange={(e) => handleTableChange(index, rowIndex, 'value', e.target.value)}
                    className="w-1/2 p-2 border rounded"
                    placeholder="Value"
                  />
                </div>
              ))}
              <button
                onClick={() => addTableRow(index)}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Add Row
              </button>
            </div>
          )}
        </div>
      ))}

      <button onClick={downloadPDF} className="bg-blue-500 text-white px-4 py-2 rounded">
        Download PDF
      </button>
    </div>
  );
};

export default ProposalGenerator;
