import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

const CLOUDINARY_CLOUD_NAME = "dlxtcvj93";
const CLOUDINARY_UPLOAD_PRESET = "sd1xay79";

interface VideoUploaderProps {
  addedVideos: string[];
  onChange: (videos: string[]) => void;
  maxVideos?: number;
}

const VideoUploader: React.FC<VideoUploaderProps> = ({
  addedVideos,
  onChange,
  maxVideos = 1
}) => {
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    // Load Cloudinary Upload Widget script
    const script = document.createElement('script');
    script.src = 'https://upload-widget.cloudinary.com/global/all.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const openUploadWidget = () => {
    //@ts-ignore
    if (window?.cloudinary) {
        //@ts-ignore
      const uploadWidget = window.cloudinary.createUploadWidget(
        {
          cloudName: CLOUDINARY_CLOUD_NAME,
          uploadPreset: CLOUDINARY_UPLOAD_PRESET,
          sources: ['local', 'url', 'camera'],
          maxFiles: maxVideos - addedVideos.length,
          multiple: maxVideos > 1,
          resourceType: 'video',
          maxFileSize: 100000000, // 100MB
        },
        //@ts-ignore
        (error, result) => {
          if (!error && result && result.event === "success") {
            const newVideo = result.info.secure_url;
            onChange([...addedVideos, newVideo]);
            toast.success('Video uploaded successfully');
            setIsUploading(false);
          }
          if (error) {
            console.error('Upload error:', error);
            toast.error('Failed to upload video');
            setIsUploading(false);
          }
        }
      );
      setIsUploading(true);
      uploadWidget.open();
    } else {
      console.error('Cloudinary Upload Widget is not loaded');
      toast.error('Upload widget is not available');
    }
  };

  const removeVideo = (videoUrl: string) => {
    onChange(addedVideos.filter(v => v !== videoUrl));
  };

  return (
    <div className="mt-2 grid gap-2 grid-cols-1">
      {addedVideos.map((videoUrl) => (
        <div key={videoUrl} className="relative">
          <video
            className="w-full h-auto rounded-lg"
            src={videoUrl}
            controls
          />
          <button
            onClick={() => removeVideo(videoUrl)}
            className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      ))}
      {addedVideos.length < maxVideos && (
        <button
          onClick={openUploadWidget}
          disabled={isUploading}
          className="h-32 w-full cursor-pointer flex items-center justify-center border border-dashed border-gray-300 rounded-lg p-2 text-gray-600 hover:border-gray-400 transition-colors"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
          </svg>
          {isUploading ? "Uploading..." : "Upload Video"}
        </button>
      )}
    </div>
  );
};

export default VideoUploader;