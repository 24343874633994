import { useParams } from "react-router-dom";
import { Loader } from "lucide-react";
import { useEffect, useState } from "react";
// import Preview from "components/Report/MyDocument";
import axios from "axios";

const Report = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [Report, setReport] = useState<any>(null);

  useEffect(()=>{
    handleGetReport();
  }, [])

  const handleGetReport = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/report/${id}`,
        { 
          headers: { 
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setReport(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <Loader className="animate-spin text-blue-600" size={48} />
      </div>
    );
  }

  if (!loading && !Report) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <h1 className="text-2xl font-semibold">Report not found</h1>
      </div>
    );
  }

  return (
    <div className="p-5">
        {/* <Preview report={Report} /> */}
    </div>
  );
};

export default Report;