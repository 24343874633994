import { useParams } from "react-router-dom";
import { Loader } from "lucide-react";
import { useEffect, useState } from "react";
import Modal from "Common/Components/Modal";
import AnimationButton from "components/UIElement/UiButtons/AnimationButton";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import axios from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "store/useAuthStore";

const Blog = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingServiceAdvisors, setLoadingServiceAdvisors] = useState(true);
  const [Blog, setBlog] = useState<any>(null);
  const [assigning, setAssigning] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [serviceAdvisors, setServiceAdvisors] = useState<any>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    handleGetBlog();
  }, []);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      serviceAdvisor: "",
    },
    validationSchema: Yup.object({
      serviceAdvisor: Yup.string().required("Service Advisor is required"),
    }),

    onSubmit: async (values) => {
      const newData = {
        ...values,
      };
      setAssigning(true);
      try {
        await axios.put(
          `${process.env.REACT_APP_BASE_URI}/blog/${id}/assign`,
          {
            serviceAdvisorId: newData.serviceAdvisor,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        handleGetBlog();
        toast.success("Blog assigned successfully");
        toggle();
        validation.resetForm();
      } catch (error: any) {
        if (!error.response) {
          return toast.error("Network error. Please try again.");
        }
        if (typeof error.response.data === "string") {
          return toast.error(error.response.data);
        }
      } finally {
        setAssigning(false);
      }
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
      validation.resetForm();
    }
  }, [show, validation]);

  const handleGetBlog = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/blog/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setBlog(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBlog = async () => {
    setDeleting(true);
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URI}/blog/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      toast.success("Blog deleted successfully");
      navigate("/blogs");
    } catch (error) {
      console.log(error);
    } finally {
      setDeleting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <Loader className="animate-spin text-blue-600" size={48} />
      </div>
    );
  }

  if (!loading && !Blog) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <h1 className="text-2xl font-semibold">Blog not found</h1>
      </div>
    );
  }

  return (
    <div className="p-5">
      <h1 className="text-2xl font-semibold mb-5">Blog Details</h1>
      <div className="flex w-full justify-between">
        <div className="bg-white shadow-md rounded-lg overflow-hidden w-[70%]">
          <div className="p-5">
            <h2 className="text-xl font-semibold">Car Details</h2>
            <p>Make: {Blog.carDetails.vehicleMake}</p>
            <p>Model: {Blog.carDetails.vehicleModel}</p>
            <p>Year: {Blog.carDetails.vehicleYear}</p>
            <p>License Plate: {Blog.carDetails.vehicleLicensePlate}</p>
          </div>
          <div className="bg-gray-100 p-5">
            <h2 className="text-xl font-semibold">Customer Details</h2>
            <p>Id: {Blog.customerId._id}</p>
            <p>Name: {Blog.customerId.name}</p>
            <p>Email: {Blog.customerId.email}</p>
          </div>
          <div className="p-5">
            <h2 className="text-xl font-semibold">Blog Info</h2>
            <p>Issue: {Blog.issueDescription}</p>
            <p>Service Type: {Blog.serviceType}</p>
            <p>Status: {Blog.status}</p>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-lg overflow-hidden w-[27%] flex flex-col p-6">
          <h2 className="text-xl font-semibold">Actions</h2>

          <AnimationButton
            label="Assign to Service Advisor"
            disabled={Blog.status === "assigned"}
            onClick={toggle}
            className="mt-3 flex items-center justify-center"
            loading={assigning}
            loadingText="Assigning..."
          />
          {Blog.status === "assigned" && (
            <p className="text-sm mt-2 text-gray-500">
              Blog already assigned to a Service Advisor.
            </p>
          )}
          {user?.role === "serviceAdvisor" && (
            <>
              <AnimationButton
                label="Open Conversation"
                disabled={!Blog.conversationId}
                onClick={() => {
                  navigate(`/conversations?conversationId=${Blog.conversationId}`);
                }}
                className="mt-3 flex items-center justify-center "
                loading={false}
              />
              {!Blog.conversationId && (
                <p className="text-sm mt-2 text-red-500">
                  No conversation found for this Blog
                </p>
              )}
            </>
          )}

          <AnimationButton
            disabled={user?.role === "serviceAdvisor"}
            label="Delete Blog"
            onClick={handleDeleteBlog}
            className={`mt-3 flex items-center justify-center bg-red-500 border-red-500 hover:bg-red-600 ${
              user?.role === "serviceAdvisor" ? "cursor-not-allowed bg-gray-300 border-gray-300 hover:bg-gray-300" : ""
            }`}
            loading={deleting}
            loadingText="Deleting..."
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">
            {"Assign to Service Advisor"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form
            onSubmit={validation.handleSubmit} // This ensures the form submission triggers Formik's handleSubmit
          >
            <div className="mb-3">
              <label
                htmlFor="serviceAdvisor"
                className="inline-block mb-2 text-base font-medium"
              >
                Service Advisor
              </label>
              {loadingServiceAdvisors ? (
                <Loader className="animate-spin text-blue-600" size={24} />
              ) : (
                <select
                  className="form-input border-slate-300 focus:outline-none focus:border-custom-500"
                  id="serviceAdvisor"
                  name="serviceAdvisor"
                  onChange={validation.handleChange} // Ensure this matches the Formik instance's handleChange
                  value={validation.values.serviceAdvisor}
                >
                  <option value="" disabled>
                    Select Service Advisor
                  </option>
                  {serviceAdvisors.map((advisor: any) => (
                    <option key={advisor._id} value={advisor._id}>
                      {advisor.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="button" // Use type="button" to prevent accidental form submissions
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:bg-custom-600"
              >
                Assign
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Blog;