import { useParams } from "react-router-dom";
import { Loader } from "lucide-react";
import { useEffect, useState } from "react";
import AnimationButton from "components/UIElement/UiButtons/AnimationButton";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Quotation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [Quotation, setQuotation] = useState<any>(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    handleGetQuotation();
  }, []);

  const handleGetQuotation = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/quotation/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setQuotation(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteQuotation = async () => {
    setDeleting(true);
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URI}/quotation/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      toast.success("Quotation deleted successfully");
      navigate("/enquiries");
    } catch (error) {
      console.log(error);
      toast.error("This action is not permitted.");
    } finally {
      setDeleting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <Loader className="animate-spin text-blue-600" size={48} />
      </div>
    );
  }

  if (!loading && !Quotation) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <h1 className="text-2xl font-semibold">Quotation not found</h1>
      </div>
    );
  }

  return (
    <div className="p-5">
      <h1 className="text-2xl font-semibold mb-5">Quotation Details</h1>
      <div className="flex w-full justify-between">
        <div className="bg-white shadow-md rounded-lg overflow-hidden w-[70%]">
          <div className="p-5">
            <h2 className="text-xl font-semibold">Quotation Info</h2>
            <p className="mt-2">
              Enquiry ID: &nbsp;
              <Link
                className="text-blue-500 hover:underline"
                to={`/enquiries/${Quotation.enquiryId}`}
              >
                {Quotation.enquiryId}
              </Link>
            </p>
            <p>
              Conversation ID: &nbsp;
              <span>{Quotation.conversationId}</span>
            </p>
            <p>
              Price Quotated: &nbsp;
              <span>{Quotation.price} AED</span>
            </p>
            <p>
              Description: &nbsp;
              <span>{Quotation.description}</span>
            </p>
            <p>Status: {Quotation.status}</p>
          </div>
        </div>
        <div className="bg-white max-h-[200px] shadow-md rounded-lg overflow-hidden w-[27%] flex flex-col p-6">
          <h2 className="text-xl font-semibold">Actions</h2>
          <AnimationButton
            label="Delete Quotation"
            onClick={handleDeleteQuotation}
            className="mt-3 flex items-center justify-center bg-red-500 border-red-500 hover:bg-red-600 hover:border-red-500"
            loading={deleting}
            loadingText="Deleting..."
          />
        </div>
      </div>
    </div>
  );
};

export default Quotation;
