import {
  MonitorDot,
  UserRound,
  Mail,
  MessageSquareMore,
  Settings,
  CreditCard,
  Briefcase,
  FileText,
  FilePlus,
  Newspaper
} from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "dashboard",
    label: "Dashboard",
    link: "/",
    icon: <MonitorDot />,
  },
  
  {
    label: "Enquiries",
    isTitle: true,
  },
  {
    id: "enquiries",
    label: "Manage Enquiries",
    link: "/enquiries",
    icon: <Mail />,
  },
  {
    label: "Projects",
    isTitle: true,
  },
  {
    id: "projects",
    label: "Manage Projects",
    link: "/projects",
    icon: <Briefcase />,
  },
  {
    label: "Financials",
    isTitle: true,
  },
  {
    id: "expenses",
    label: "Manage Expenses",
    link: "/expenses",
    icon: <FilePlus />,
  },
  {
    id: "quotations",
    label: "Manage Quotations",
    link: "/quotations",
    icon: <FilePlus />,
  },
  {
    id: "invoices",
    label: "Manage Invoices",
    link: "/invoices",
    icon: <FileText />,
  },
  {
    id: "payments",
    label: "Manage Payments",
    link: "/payments",
    icon: <CreditCard />,
  },
  {
    label: "Marketing",
    isTitle: true,
  },
  {
    id: "marketing-sm-postings",
    label: "SM Postings",
    link: "/sm-postings",
    icon: <MessageSquareMore />,
  },
  {
    id: "blogs",
    label: "Blogs",
    link: "/blogs",
    icon: <Newspaper />,
  },
  {
    label: "Reports",
    isTitle: true,
  },
  {
    id: "reports",
    label: "Reports",
    link: "/reports",
    icon: <MonitorDot />,
  },
  {
    id: "proposal-generator",
    label: "Proposal Generator",
    link: "/proposal-generator",
    icon: <MonitorDot />,
  },
  {
    label: "Users",
    isTitle: true,
  },
  {
    id: "users",
    label: "Users",
    link: "/users",
    icon: <UserRound />,
    parentId: 2,
  },
  {
    label: "Settings",
    isTitle: true,
  },
  {
    id: "settings",
    label: "Settings",
    link: "/settings",
    icon: <Settings />,
  },
  
];

export { menuData };
