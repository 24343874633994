import Dashboard from "pages/Dashboard/Analytics";
import Enquiries from "pages/Enquiries/ListView";
import Enquiry from "pages/Enquiries/Enquiry";
import UsersTable from "pages/Users/ListView";
import UserProfile from "pages/UserProfile";
import Project from "pages/Projects/Project";
import Projects from "pages/Projects/ListView";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Quotations from "pages/Quotations/ListView";
import Quotation from "pages/Quotations/Quotation";
import PickupUpdate from "pages/PickupUpdate";
import Blogs from "pages/Blogs/ListView";
import Blog from "pages/Blogs/Blog";
import Reports from "pages/Reports/ListView";
import Report from "pages/Reports/Report";
import ProposalGenerator from "pages/Proposal";

import SMPostings from "pages/Marketing/ListView";
import PageDetails from "pages/Marketing/Page";
import Expenses from "pages/Expenses/ListView";

interface RouteObject {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
}

let authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Dashboard },
  { path: "/dashboard", component: Dashboard },

  // Users
  { path: "/users", component: UsersTable },

  // Enquiry
  { path: "/enquiries", component: Enquiries },
  { path: "/enquiries/:id", component: Enquiry },

  // profile
  { path: "/user-profile", component: UserProfile },

  // Reports
  { path: "/reports", component: Reports },
  { path: "/reports/:id", component: Report },

  // Marketing Department
  { path: "/sm-postings", component: SMPostings },
  {path: "/sm-postings/:pageId", component: PageDetails},
  { path: "/blogs", component: Blogs },
  { path: "/blogs/:id", component: Blog },

  // jobs
  { path: "/projects", component: Projects },
  { path: "/projects/:id", component: Project },

  // quotations
  { path: "/quotations", component: Quotations },
  { path: "/quotations/:id", component: Quotation },

  // expenses
  {path: "/expenses", component: Expenses},

  // proposal generator
  {path: "/proposal-generator", component: ProposalGenerator}

];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/update-progress/:token", component: PickupUpdate},
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
