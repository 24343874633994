import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useCallback } from "react";
import PhotosUploader from "components/Forms/ImageUploader";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";

const PickupUpdate = () => {
  const { token } = useParams();
  const [posting, setPosting] = useState(false);
  const [validating, setValidating] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [job, setJob] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    if (images) {
      validation.setFieldValue("images", images);
    } else {
      validation.setFieldValue("images", []);
    }
  }, [images]);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      images: Yup.array(),
    }),

    onSubmit: async (values) => {
      const newData = {
        ...values,
      };
      setPosting(true);
      try {
        await axios.put(
          `${process.env.REACT_APP_BASE_URI}/job/${job.id}/pickup-update`,
          {
            ...newData,
            token,
          }
        );
        toast.success("Progress update sent successfully");
        validation.resetForm();
        setImages([]);
      } catch (error: any) {
        if (!error.response) {
          return toast.error("Network error. Please try again.");
        }
        if (typeof error.response.data === "string") {
          return toast.error(error.response.data);
        }
      } finally {
        setPosting(false);
      }
    },
  });

  useEffect(() => {
    handleValideToken();
  }, []);

  const handleValideToken = useCallback(async () => {
    setValidating(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/job/validate-token/${token}`
      );
      setJob(res.data);
      toast.success("Token validated successfully");
    } catch (error:any) {
      if (!error.response) {
        setError("Network error. Please try again.");
        return toast.error("Network error. Please try again.");
      }
      if (typeof error.response.data === "string") {
        setError(error.response.data);
        return toast.error(error.response.data);
      }
    } finally {
      setValidating(false);
    }
  }, []);

  if (validating) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  if (error || !job) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen bg-slate-50 dark:bg-zink-800">
      <Toaster />

      <form
        onSubmit={validation.handleSubmit}
        className="bg-white dark:bg-zink-800 p-4 rounded-lg shadow-md dark:shadow-lg w-full sm:w-96 md:w-96 lg:w-96 xl:w-96 2xl:w-96 mx-auto mt-4  border-slate-100 dark:border-zink-700 border-2"
      >
        <div className="text-center text-xl font-medium mt-2 mb-6">
          Add pickup updates for {job?.enquiryId?.carDetails?.vehicleMake}{" "}
          {job?.enquiryId?.carDetails?.vehicleModel}{" "}
          {job?.enquiryId?.carDetails?.vehicleYear}
        </div>
        <div className="mb-3">
          <label
            htmlFor="serviceAdvisor"
            className="inline-block mb-2 text-base font-medium"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={validation.values.title}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            placeholder="Pickup update title"
            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
          />
          {validation.touched.title && validation.errors.title ? (
            <p className="text-red-500 text-xs mt-1">
              {validation.errors.title}
            </p>
          ) : null}
        </div>
        <div className="mb-3">
          <label
            htmlFor="serviceAdvisor"
            className="inline-block mb-2 text-base font-medium"
          >
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={validation.values.description}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            placeholder="Description"
            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
          />
          {validation.touched.description && validation.errors.description ? (
            <p className="text-red-500 text-xs mt-1">
              {validation.errors.description}
            </p>
          ) : null}
        </div>
        <div className="mb-3">
          <label
            htmlFor="images"
            className="inline-block mb-2 text-base font-medium"
          >
            Images
          </label>
          <PhotosUploader
            addedPhotos={images}
            onChange={setImages}
            maxPhotos={5}
          />
        </div>
        <div className="flex justify-end gap-2 mt-4">
          <button
            disabled={posting}
            type="submit"
            className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:bg-custom-600"
          >
            {posting ? "Sending..." : "Send"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PickupUpdate;
