import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    Link,
    pdf,
  } from "@react-pdf/renderer";
  import { PDFDocument } from "pdf-lib";
  
  const styles = StyleSheet.create({
    page: { 
      padding: 40, 
      fontFamily: "Helvetica", 
      backgroundColor: "#f7f7f7" 
    },
    logo: { 
      width: 120, 
      height: 120, 
      alignSelf: "center", 
      marginBottom: 20 
    },
    title: { 
      fontSize: 28, 
      fontWeight: "bold", 
      marginBottom: 10, 
      color: "#000000", 
      textAlign: "center" 
    },
    subtitle: { 
      fontSize: 20, 
      fontWeight: "medium", 
      marginBottom: 20, 
      color: "#000000", 
      textAlign: "center" 
    },
    text: { 
      fontSize: 14, 
      marginBottom: 8, 
      color: "#424242", 
      lineHeight: 1.5 
    },
    section: { 
      marginBottom: 20, 
      padding: 10, 
      backgroundColor: "#ffffff", 
    },
    table: { 
      display: "flex", 
      width: "100%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderColor: "#bdbdbd", 
      marginBottom: 20, 
      overflow: "hidden"
    },
    tableRow: { 
      flexDirection: "row", 
      borderBottomWidth: 1, 
      borderBottomColor: "#e0e0e0", 
      backgroundColor: "#ffffff" 
    },
    tableCol: { 
      width: "33%", 
      padding: 8 
    },
    tableCell: { 
      fontSize: 12, 
      color: "#424242" 
    },
    tableHeader: { 
      fontSize: 14, 
      fontWeight: "bold", 
      color: "#ffffff", 
      backgroundColor: "#d32f2f", 
      padding: 8 
    },
    footer: { 
      position: "absolute", 
      bottom: 30, 
      left: 0, 
      right: 0, 
      textAlign: "center", 
      color: "#757575", 
      fontSize: 10 
    },
  });
  
  const ReportContent = ({
    data,
    startDate,
    endDate,
    customNote,
  }: {
    data: any;
    startDate: string;
    endDate: string;
    customNote: string;
  }) => (
    <Page size="A4" style={styles.page}>
      <Image style={styles.logo} src={data.brandLogo} />
      <Text style={styles.title}>{data.companyName}</Text>
      <Text style={styles.subtitle}>Social Media Performance Report</Text>
  
      {customNote && (
        <View style={styles.section}>
          <Text style={[styles.text, { fontStyle: "italic" }]}>{customNote}</Text>
        </View>
      )}
  
      <View style={styles.section}>
        <Text style={styles.text}>
          This report covers {data.companyName}'s social media activity from {startDate} to {endDate} 
          {" "}across Instagram, LinkedIn, and Facebook platforms.
        </Text>
      </View>
  
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableCol}>
            <Text style={styles.tableHeader}>Platform</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHeader}>Posts</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableHeader}>Link</Text>
          </View>
        </View>
        {data.socialMediaPlatforms.map((platform: any, index: any) => (
          <View
            key={platform.name}
            style={[
              styles.tableRow,
              //@ts-ignore
              index % 2 !== 0 && { backgroundColor: "#f7f7f7" },
            ]}
          >
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{platform.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{platform.postCount}</Text>
            </View>
            <View style={styles.tableCol}>
              <Link src={platform.link}>
                <Text style={[styles.tableCell, { color: "#d32f2f" }]}>
                  {platform.handle}
                </Text>
              </Link>
            </View>
          </View>
        ))}
      </View>
  
      <Text style={styles.footer}>
        This report is system-generated and accurate as of {new Date().toLocaleString()}
      </Text>
    </Page>
  );
  
  const ReportDocument = (props: any) => (
    <Document>
      <ReportContent {...props} />
    </Document>
  );
  
  const generatePDFWithCover = async (props: any) => {
    const reportPdf = await pdf(<ReportDocument {...props} />).toBlob();
    const pdfDoc = await PDFDocument.load(await reportPdf.arrayBuffer());
  
    const coverPdfUrl = `${process.env.PUBLIC_URL}/SMREPORTCOVER.pdf`;
    const coverPdfBytes = await fetch(coverPdfUrl).then(res => res.arrayBuffer());
    const coverPdf = await PDFDocument.load(coverPdfBytes);
  
    const [coverPage] = await pdfDoc.copyPages(coverPdf, [0]);
    pdfDoc.insertPage(0, coverPage);
    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], { type: 'application/pdf' });
  };
  
  export { ReportDocument, generatePDFWithCover };  