import { useParams } from "react-router-dom";
import { Loader } from "lucide-react";
import { useEffect, useState } from "react";
import Modal from "Common/Components/Modal";
import AnimationButton from "components/UIElement/UiButtons/AnimationButton";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import axios from "axios";
import { useCallback } from "react";
import moment from "moment";

const Project = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [Project, setProject] = useState<any>(null);
  const [posting, setPosting] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    handleGetProject();
  }, []);

  useEffect(() => {
    if (images) {
      validation.setFieldValue("images", images);
    } else {
      validation.setFieldValue("images", []);
    }
  }, [images]);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: 0,
      paymentDate: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required("Amount is required")
        .min(1, "Amount must be greater than 0"),
      paymentDate: Yup.string().required("Payment date is required"),
    }),

    onSubmit: async (values) => {
      const newData = {
        ...values,
      };
      setPosting(true);
      try {
        await axios.put(
          `${process.env.REACT_APP_BASE_URI}/project/${id}/add-payment`,
          {
            ...newData,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        handleGetProject();
        toast.success("Project assigned successfully");
        toggle();
        validation.resetForm();
        setImages([]);
      } catch (error: any) {
        if (!error.response) {
          return toast.error("Network error. Please try again.");
        }
        if (typeof error.response.data === "string") {
          return toast.error(error.response.data);
        }
        toast.error("Something went wrong. Please try again.");
      } finally {
        setPosting(false);
      }
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
      validation.resetForm();
    }
  }, [show, validation]);

  const handleGetProject = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/project/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setProject(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <Loader className="animate-spin text-blue-600" size={48} />
      </div>
    );
  }

  if (!loading && !Project) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <h1 className="text-2xl font-semibold">Project not found</h1>
      </div>
    );
  }

  return (
    <div className="p-5">
      <h1 className="text-2xl font-semibold mb-5">Project Details</h1>
      <div className="flex w-full justify-between">
        <div className="bg-white shadow-md rounded-lg overflow-hidden w-[70%]">
          <div className="bg-white p-5">
            <p>Id: {Project._id}</p>
            <p>Title: {Project.title}</p>
            <p>Description: {Project.description}</p>
            <p>Service: {Project.service}</p>
            <p>Rate: {Project.rate} AED</p>
            <p>Billing Frequency: {Project.billingFrequency}</p>
            <p>Total Received: {Project.totalReceived} AED</p>
            <p>Receivable: {Project.receivable} AED</p>
            <p>Status: {Project.status}</p>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between mt-6">
        <div className="bg-white shadow-md rounded-lg overflow-hidden w-[70%]">
          <div className="bg-white-100 p-5">
            <div className="flex justify-between">
              <h4>Payments</h4>
              <AnimationButton
                loading={posting}
                label={"Create Payment"}
                onClick={toggle}
              />
            </div>
            <div>
              {Project.payments.map((payment: any) => (
                <div key={payment.createdAt} className="flex mt-3 justify-between">
                  <p>{payment.amount} AED</p>
                  <p
                  className="text-xs text-gray-400 dark:text-zink-300"
                  >{moment(payment.paymentDate).format("YYYY-MM-DD")}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        <Modal.Header
          className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
          closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
        >
          <Modal.Title className="text-16">{"Create Payment"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
          <form onSubmit={validation.handleSubmit}>
            <div className="mb-3">
              <label
                htmlFor="serviceAdvisor"
                className="inline-block mb-2 text-base font-medium"
              >
                Amount
              </label>
              <input
                type="number"
                id="amount"
                name="amount"
                value={validation.values.amount}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                placeholder="0"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              />
              {validation.touched.amount && validation.errors.amount ? (
                <p className="text-red-500 text-xs mt-1">
                  {validation.errors.amount}
                </p>
              ) : null}
            </div>
            <div className="mb-3">
              <label
                htmlFor="paymentDate"
                className="inline-block mb-2 text-base font-medium"
              >
                Payment Date
              </label>
              <input
              type="date"
              id="paymentDate"
              name="paymentDate"
              value={validation.values.paymentDate}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              placeholder=""
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
            />
            {validation.touched.paymentDate && validation.errors.paymentDate ? (
              <p className="text-red-500 text-xs mt-1">
                {validation.errors.paymentDate}
              </p>
            ) : null}
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="button"
                className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                disabled={posting}
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:bg-custom-600"
              >
                {posting ? "Creating..." : "Create"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Project;
