import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import { useAuthStore } from "store/useAuthStore";

// Components
import BreadCrumb from "Common/BreadCrumb";
import TableContainer from "Common/TableContainer";
import { Dropdown } from "Common/Components/Dropdown";

// Icons
import { Download, MoreHorizontal, Search, Trash, Loader } from "lucide-react";

const Expenses = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URI}/expenses`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setExpenses(response.data);
    } catch (error) {
      console.error("Error fetching expenses:", error);
      toast.error("Failed to fetch expenses");
    } finally {
      setLoading(false);
    }
  };

  const deleteExpense = async (id:any) => {
    if (user?.role !== "admin") {
      return toast.error("You are not authorized to delete expenses!");
    }
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URI}/expenses/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      toast.success("Expense deleted successfully!");
      fetchExpenses();
    } catch (error) {
      console.error("Error deleting expense:", error);
      toast.error("Failed to delete expense");
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Select",
        enableSorting: false,
        id: "select",
        cell: () => (
          <input
            type="checkbox"
            className="size-4 bg-white border rounded-sm appearance-none cursor-pointer border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 checked:border-custom-500 dark:checked:border-custom-800"
          />
        ),
      },
      {
        header: "Expense ID",
        accessorKey: "_id",
        enableColumnFilter: false,
        cell: (info:any) => (
          <Link to={`/expenses/${info.getValue()}`} className="text-custom-500 hover:text-custom-600">
            {info.getValue()}
          </Link>
        ),
      },
      {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        cell: (info:any) => `$${info.getValue().toFixed(2)}`,
      },
      {
        header: "Date",
        accessorKey: "date",
        enableColumnFilter: false,
        cell: (info:any) => moment(info.getValue()).format("DD/MM/YYYY"),
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info:any) => (
          <Dropdown className="relative">
            <Dropdown.Trigger className="flex items-center justify-center size-[30px] p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20">
              <MoreHorizontal className="size-3" />
            </Dropdown.Trigger>
            <Dropdown.Content className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md min-w-[10rem] dark:bg-zink-600">
              <li>
                <Link to={`/expenses/${info.row.original._id}`} className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200">
                  View Details
                </Link>
              </li>
              <li>
                <button
                  onClick={() => deleteExpense(info.row.original._id)}
                  className="block w-full px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 hover:bg-slate-100 hover:text-red-500 focus:bg-slate-100 focus:text-red-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-red-500 dark:focus:bg-zink-500 dark:focus:text-red-500"
                >
                  <Trash className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                  <span className="align-middle">Delete</span>
                </button>
              </li>
            </Dropdown.Content>
          </Dropdown>
        ),
      },
    ],
    []
  );

  const downloadExpensesExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      expenses.map((item:any) => ({
        "Expense ID": item._id,
        Title: item.title,
        Amount: item.amount,
        Date: moment(item.date).format("DD/MM/YYYY"),
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Expenses");
    XLSX.writeFile(workbook, "Expenses.xlsx");
  };

  const handleSearch = (e:any) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredExpenses = expenses.filter((expense) =>
      Object.values(expense).some(
        (value) =>
          typeof value === "string" && value.toLowerCase().includes(searchTerm)
      )
    );
    setExpenses(filteredExpenses);
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Expenses" pageTitle="Finance" />
      <ToastContainer closeButton={false} limit={1} />
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <div className="card" id="expenseTable">
        <div className="card-body">
          <div className="flex items-center gap-2">
            <h6 className="text-15 grow">Expenses List</h6>
            <div className="flex gap-2">
              <button
                onClick={downloadExpensesExcel}
                type="button"
                className="text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
              >
                <Download className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                <span className="align-middle">Export</span>
              </button>
              <Link
                to="/expenses/new"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                Add Expense
              </Link>
            </div>
          </div>
          <div className="px-3.5 py-2.5 mt-4 border-y border-dashed border-slate-200 dark:border-zink-500">
            <div className="relative">
              <input
                type="text"
                className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                placeholder="Search for title, amount, date, etc..."
                autoComplete="off"
                onChange={handleSearch}
              />
              <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
            </div>
          </div>
        </div>
        <div className="card-body">
          {expenses.length > 0 ? (
            <TableContainer
              isPagination={true}
              columns={columns}
              data={expenses}
              customPageSize={10}
              tableClass="w-full whitespace-nowrap"
              PaginationClassName="flex flex-col items-center mt-8 md:flex-row"
            />
          ) : (
            <div className="noresult">
              <div className="py-6 text-center">
                <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                <p className="mb-0 text-slate-500 dark:text-zink-200">
                  We've searched all expenses but we did not find any expenses for your search.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Expenses;