import React from "react";
import {
  Loader,
  Kanban,
  Users,
  CircleDollarSign,
  MailCheck,
  FolderDot,
} from "lucide-react";
import axios from "axios";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import toast from "react-hot-toast";

const Widgets = ({
  startDate,
  endDate
}:{
  startDate: Date;
  endDate: Date;
}) => {


  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    usersCount: 0,
    projectsCount: 0,
    monthlyProjectsCount: 0,
    oneTimeProjectsCount: 0,
    totalReceivable: 0,
    enquiriesCount: 0,
    totalRevenue: 0,
  });

  useEffect(() => {
    handleGetStats(startDate, endDate);
  }, [startDate, endDate]);

  const handleGetStats = async (startDate: Date, endDate: Date) => {
    try {
      setLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_BASE_URI}/stats?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const {
        usersCount,
        projectsCount,
        totalReceivable,
        enquiriesCount,
        totalRevenue,
        monthlyProjectsCount,
        oneTimeProjectsCount,
      } = res.data;
      setData({
        usersCount,
        enquiriesCount,
        projectsCount,
        monthlyProjectsCount,
        oneTimeProjectsCount,
        totalReceivable,
        totalRevenue,
      });
    } catch (error: any) {
      if (!error.response) {
        return toast.error("Network error. Please try again.");
      }
      if (typeof error.response.data === "string") {
        return toast.error(error.response.data);
      }
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-90 dark:bg-zink-900 dark:bg-opacity-90">
          <Loader className="size-10 text-custom-500 animate-spin" />
        </div>
      )}
      <div className="order-1 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-orange-100 dark:bg-orange-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-orange-200/50 dark:text-orange-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-orange-500 rounded-md text-15 text-orange-50">
            <CircleDollarSign />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalRevenue} className="counter-value" /> AED
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Revenue</p>
        </div>
      </div>
      <div className="order-2 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-orange-100 dark:bg-orange-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-orange-200/50 dark:text-orange-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-orange-500 rounded-md text-15 text-orange-50">
            <CircleDollarSign />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.totalReceivable} className="counter-value" /> AED
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Receivable</p>
        </div>
      </div>
      <div className="order-3 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-green-100 dark:bg-green-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <FolderDot />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.projectsCount} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Projects</p>
        </div>
      </div>
      <div className="order-4 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-green-100 dark:bg-green-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <FolderDot />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={data.monthlyProjectsCount}
              className="counter-value"
            />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Total Monthly Projects
          </p>
        </div>
      </div>
      <div className="order-5 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-green-100 dark:bg-green-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <FolderDot />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp
              end={data.oneTimeProjectsCount}
              className="counter-value"
            />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">
            Total Other Project
          </p>
        </div>
      </div>
      <div className="order-6 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-purple-100 dark:bg-purple-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-purple-200/50 dark:text-purple-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-purple-500 rounded-md text-15 text-purple-50">
            <Users />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.usersCount} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Users</p>
        </div>
      </div>
      <div className="order-7 md:col-span-6 lg:col-span-3 col-span-12 2xl:order-1 bg-green-100 dark:bg-green-500/20 card 2xl:col-span-2 group-data-[skin=bordered]:border-purple-500/20 relative overflow-hidden">
        <div className="card-body">
          <Kanban className="absolute top-0 size-32 stroke-1 text-green-200/50 dark:text-green-500/20 ltr:-right-10 rtl:-left-10"></Kanban>
          <div className="flex items-center justify-center size-12 bg-green-500 rounded-md text-15 text-green-50">
            <MailCheck />
          </div>
          <h5 className="mt-5 mb-2">
            <CountUp end={data.enquiriesCount} className="counter-value" />
          </h5>
          <p className="text-slate-500 dark:text-slate-200">Total Enquries</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Widgets;
